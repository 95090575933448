<template>
  <DataTable :options="options" />
</template>

<script>
import { VENUE_TYPE, VENUE_TYPES } from '@tutti/constants';

export default {
  data() {
    return {
      options: {
        filter: {
          system: false,
        },
        headers: [
          { text: 'Thumbnail', value: 'thumbnail', component: { name: 'dt-iframe' } },
          { text: 'Title', value: 'meta.title', filter: true },
          { text: 'Source', value: 'meta.site', filter: true },
          { text: 'URL', value: 'url', component: { name: 'dt-link' }, filter: true },
          { text: 'Venue type', value: 'venueType', capitalize: true, filter: { type: 'select', items: VENUE_TYPES } },
          {
            text: 'Venue',
            value: 'venue',
            filter: false,
            sortable: false,
            getter: item => {
              if (item.venueType === VENUE_TYPE.SPACE) {
                return item.spaceId?.name;
              } else if (item.venueType === VENUE_TYPE.LOCATION) {
                return item.locationId?.name;
              }
            },
            component: {
              name: 'dtView',
              props: {
                getter: item => {
                  if (item.spaceId && item.venueType === VENUE_TYPE.SPACE) {
                    return this.$toView('space', item.spaceId._id);
                  } else if (item.locationId && item.venueType === VENUE_TYPE.LOCATION) {
                    return this.$toView('location', item.locationId._id);
                  }
                },
                permission: item => {
                  if (item.spaceId && item.venueType === VENUE_TYPE.SPACE) {
                    return 'space';
                  } else if (item.locationId && item.venueType === VENUE_TYPE.LOCATION) {
                    return 'location';
                  }
                },
              },
            },
          },
          {
            text: 'Profile',
            value: 'account',
            filter: false,
            sortable: false,
            getter: item => {
              return item.artistAccount?.name || item.hostAccount?.name;
            },
            component: {
              name: 'dtView',
              props: {
                getter: item => {
                  if (item.artistAccount) {
                    return this.$toView('account', item.artistAccount._id);
                  } else if (item.hostAccount) {
                    return this.$toView('account', item.hostAccount._id);
                  }
                },
                permission: 'account',
              },
            },
          },
          {
            text: 'Verified',
            value: 'isVerified',
            width: '50px',
            component: { name: 'dt-checkbox', props: { action: true } },
          },
        ],
        hideAction: true,
      },
    };
  },
};
</script>
